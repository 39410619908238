<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container h-100 bg-first p-1">
      <b-card>
        <div>
          <h3 class="m-0">
            รายการสั่งซื้อสลากฯ
          </h3>
          <small>คุณเหลือเวลาอีก <span class="text-danger"> 00 : {{ CountTimeShow ? CountTimeShow: '00 : 00' }} นาที</span> ในการจ่ายเงิน เพื่อเป็นเจ้าของรายการสลาก ฯ ที่ท่านสั่งซื้อ</small>
        </div>

        <div class="mt-2">
          <h4>เฮงมากมาก ค้าสลาก มีทั้งหมด <span class="text-danger">{{ cart.length ? cart.length : 0 }} รายการ</span></h4>
        </div>

        <b-row>
          <b-col
            v-for="item in cart"
            :key="item._id"
            lg="4"
            md="6"
            cols="12"
            class="mb-2"
          >
            <div class="card-cart">
              <div class="card-lotto-set">
                <div class="img-lotto">
                  <img
                    :src="require('@/assets/images/lottery/Logo_about.png')"
                    alt="logo"
                  >
                </div>

                <div class="detail-lotto">
                  <div class="d-flex justify-content-between bd">
                    <h5 class="font-medium-3">
                      สลากเล่มที่ {{ item.set_no }}
                    </h5>
                    <h5 class="pink font-medium-3">
                      <small>สลาก</small> 100 <small>ใบ</small>
                    </h5>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>งวดวันที่ {{ item.roundth }} </span>
                    <span><small>ใบละ</small> {{ price }} <small>บาท</small></span>
                  </div>

                  <div class="d-flex justify-content-between mt-1">
                    <span>ราคาสุทธิ</span>
                    <span class="pink">{{ Commas(Number(price) * 100) }} <small>บาท</small></span>
                  </div>

                  <b-badge
                    class="d-block cursor-pointer"
                    pill
                    variant="danger"
                    @click="removeCart(item._id)"
                  >
                    ลบสลาก
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <div
        v-for="item in cart"
        :key="item._id"
        class="border rounded p-2 mb-4"
      >
        <div class="row">
          <div class="col-md-4">
            {{ item.roundth }}
            <br>
            เล่มที่
            {{ item.set_no }}
          </div>

          <div class="col-md-8">
            <tr class="d-flex justify-content-between border-bottom">
              <td style="width: 15%">
                งวดวันที่
              </td>
              <td>{{ item.roundth }}</td>
              <td> 100 ใบ</td>
            </tr>
            <tr class="d-flex justify-content-between border-bottom pt-2 pb-2">
              <td style="width: 30%">
                เล่มที่
              </td>
              <td>{{ item.set_no }}</td>
              <td />
            </tr>
            <tr class="d-flex justify-content-between border-bottom pt-2 pb-2">
              <td style="width: 30%">
                ราคาต่อหน่วย
              </td>
              <td>{{ round.agent_price }}</td>
              <td>บาท</td>
            </tr>

            <tr class="d-flex justify-content-between border-bottom pt-2 pb-2">
              <td style="width: 30%">
                ส่วนลด
              </td>
              <td>0</td>
              <td>บาท</td>
            </tr>

            <tr class="d-flex justify-content-between border-bottom pt-2 pb-2">
              <td style="width: 30%">
                รวมราคา
              </td>
              <td>{{ Commas(Number(round.agent_price) * 100) }}</td>
              <td>บาท</td>
            </tr>
          </div>
        </div>
      </div> -->
        <!-- <div>
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          align="center"
        />
      </div> -->

        <!-- <div class="mt-4">
        <h4 class="text-divider">
          <span class="text-danger text-center">ต้องการเพิ่มโบนัส</span>
        </h4>
        <div class="d-flex align-items-center justify-content-between">
          <small>เพิ่มโอกาสรับโบนัสเพิ่มอีก <span class="text-danger">10%</span> เมื่อคุณถูกรางวัล เพียงเพิ่มเงินอีก 20 บาทต่อใบ!</small>

          <button class="btn btn-gradient-primary">
            รับโบนัส
          </button>
        </div>
      </div> -->

        <div class="mt-4">
          <h4 class="text-divider">
            <span class="text-danger text-center">รวมทั้งหมด {{ Commas(Number(cart.length) * Number(price) * 100) }} บาท</span>
          </h4>
          <div>
            <h3>ซื้อสลากฯ</h3>
            <h5>ยอดเงินคงเหลือของคุณ: {{ Commas(balance) }} บาท. กรุณาเติมเงินขั้นต่ำ <span class="text-danger">{{ Commas(Number(cart.length) * Number(price) * 100) }} บาท</span></h5>
            <button
              class="btn btn-gradient-primary"
              @click="buyLottoSet"
            >
              ซื้อสลากฯ
            </button>
          </div>
        </div>

        <b-modal
          ref="my-modal"
          hide-footer
          hide-header
          centered
          title="Using Component Methods"
        >
          <div class="mt-1 mb-1">
            <h4 class="font-weight-bolder text-center">
              กรุณาโอนมาที่บัญชีที่ระบุไว้ด้านล่างนี้เท่านั้น
            </h4>
            <div class="text-center">
              <span class="font-weight-bolder">
                จำนวน<span class="font-weight-bolder text-success font-large-1"> 120.01 </span> บาท
              </span>
            </div>

            <div class="d-flex align-items-center justify-content-center mt-2">
              <div class="mr-1">
                <img
                  src="https://beta.mekonglottery.co/assets/images/bank/icon-bank-kbank.png"
                  alt="bank"
                  height="75"
                >
              </div>

              <div>
                <h5 class="font-weight-bolder">
                  ธนาคารกสิกรไทย จำกัด (มหาชน)
                </h5>
                <h5 class="font-weight-bolder">
                  123-4-56789-0
                </h5>
                <h5 class="font-weight-bolder">
                  สมชาย สม***
                </h5>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-center mt-2">
              <b-button
                variant="gradient-primary"
                class="mr-1"
              >
                คัดลอกเลขบัญชี
              </b-button>
              <b-button
                variant="outline-primary"
                @click="hideModal"
              >
                ยกเลิก
              </b-button>
            </div>
          </div>
        </b-modal>

      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BModal, BButton, BCol, BRow, BOverlay,
  BIconController, BBadge,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  name: 'Order',
  components: {
    BCard,
    // BPagination,
    BModal,
    BButton,
    BCol,
    BRow,
    BOverlay,
    BIconController,
    BBadge,
  },
  data() {
    return {
      selected: 'center',
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      cart: [],
      exlotto: [],
      round: null,
      balance: 0,
      CountTimeShow: '',
      show: false,
      agent: null,
      price: 86,
    }
  },
  async mounted() {
    await this.GetAgent()
    await this.getLottoSet()
    await this.getround()
    await this.getBalance()
  },

  methods: {
    GetAgent() {
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/agent/show')
        .then(ress => {
          // console.log(ress.data)
          this.agent = ress.data
        })
    },
    async removeCart(id) {
      const obj = {
        id,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/removelottoIncartAgent', obj)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.getLottoSet()
          // console.log(ress.data)
        })
    },
    async getLottoSet() {
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/getsetcartbyuser')
        // eslint-disable-next-line consistent-return
        .then(ress => {
          // console.log(ress.data)
          this.cart = ress.data
          if (this.cart.length === 1 || this.cart.length === 2) {
            this.price = this.agent.agent_price1
            return this.price
          } if (this.cart.length >= 3 && this.cart.length < 5) {
            this.price = this.agent.agent_price3
            return this.price
          } if (this.cart.length >= 5 && this.cart.length < 10) {
            this.price = this.agent.agent_price5
            return this.price
          } if (this.cart.length >= 10) {
            this.price = this.agent.agent_price10
            return this.price
          }
          if (this.cart[0].updated_at) {
            this.CalTime()
          }
          return this.cart
          // localStorage.setItem('lottoset', JSON.stringify(this.lottoset))
        })
    },
    async CalTime() {
      const now = momenttz()
      const duration = await momenttz.duration(now.diff(this.cart[0].updated_at))
      const Min = await duration.asMinutes()
      // console.log(10 - Min)
      const time = await momenttz().add(-30, 'minutes')
      if (this.cart[0].updated_at < time) {
        // this.Cancel()
      } else {
        this.startTimer(60 * (30 - Min))
      }
    },
    async startTimer(duration) {
      let timer = duration
      let minutes
      let seconds
      this.intervalID = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)

        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds
        // console.log(seconds)
        this.CountTimeShow = `${minutes} : ${seconds}`
        // console.log(this.CountTimeShow)
        // eslint-disable-next-line no-plusplus
        if (--timer < 0) {
          timer = duration
        }
        if (minutes === '00' && seconds === '00') {
          clearInterval(this.intervalID)
          // this.DelWaittingByUID()
        }
      }, 1000)
    },
    getround() {
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/getrounddetail')
        .then(ress => {
          this.round = ress.data
        })
    },
    buyLottoSet() {
      if (this.cart.length <= 0) {
        this.SwalError('กรุณาเลือก เล่มสลาก ที่ต้องการซื้อ')
      } else {
        this.$swal({
          title: 'แจ้งเตือน ?',
          text: 'ยืนยันที่จะซื้อสลากหรือไม่ ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            this.buyLottoSet2()
          } else {
            this.show = false
            this.$swal('ยกเลิก', 'ยกเลิกการซื้อสลาก !', 'error')
          }
        })
      }
    },
    buyLottoSet2() {
      this.show = true
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/buylottoset', {
          cart: this.cart,
        })
        .then(() => {
          localStorage.removeItem('lottoset')
          this.getBalance()
          this.Success('สั่งซื้อสำเร็จ')
          this.show = false
          this.$router.push({ name: 'lotto-list-agent' })
        }).catch(error => {
          this.SwalError(error.response.data.message ? 'สั่งซื้อไม่สำเร็จ' : error.response.data.message)
        })
    },
    showsw() {
      this.Success('สั่งซื้อสำเร็จ')
    },
    getBalance() {
      const Obj = {
        UserToken: this.UserData.UserToken,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/wallet/show', Obj)
        .then(ress => {
          // console.log(ress.data.WalletData.balance)
          this.balance = ress.data.WalletData.balance
          localStorage.setItem('balance', JSON.stringify(ress.data.WalletData.balance))
        })
    },

    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ซื้อสลากไม่สำเร็จ!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}

</script>
